/* eslint-disable dot-notation */
import EventEmitter from "eventemitter3";
import {
  Events,
  PageViewEvent,
  SellFormViewEvent,
  SYBSaveDraftEvent,
  SellFormCompletedEvent,
  ModalEvent,
  TrackingEvent,
  EventUserId,
  ShiplistSubmit,
  VAFormCompletedEvent,
  StepCompleted,
} from "../@types/events";
import { segmentPageNameMap } from "../helpers/segmentPageNameMap";
import {
  segmentWhitelistFilter,
  trackEvent,
  trackPage,
} from "./segment/segment";

// NB events.once will disable themselves (after firing) until page reload

// General
const events: Events = new EventEmitter<keyof TrackingEvent>();

events.on(
  ...segmentWhitelistFilter(
    "page_view",
    async ({ page_name, userId }: PageViewEvent) => {
      const mappedName = segmentPageNameMap[page_name];
      const formattedName = mappedName?.name || page_name;
      const site_section = mappedName?.site_section;

      const params = {
        name: formattedName,
        language_code: "en",
        site_section,
        user_id: userId,
        has_recommendation: 0,
      };

      trackPage(formattedName, params);
    }
  )
);

events.on(
  ...segmentWhitelistFilter("modal_open", async (data: ModalEvent) => {
    trackEvent("Modal Opened", data);
  })
);

events.on(
  ...segmentWhitelistFilter("modal_close", async (data: ModalEvent) => {
    trackEvent("Modal Closed", data);
  })
);

// SYB
events.on(
  ...segmentWhitelistFilter(
    "sell_form_view",
    async (data: SellFormViewEvent) => {
      trackEvent("Quote Form Started", {
        sell_form_type: "Web Quote",
        step_name: "Form Viewed",
        user_id: +data.customer_id,
        step: 1,
      });
    }
  )
);

events.on(
  ...segmentWhitelistFilter("sell_form_view_va", async (data: EventUserId) => {
    trackEvent("Appointment Form Started", {
      sell_form_type: "Virtual Appointment",
      step_name: "Form Viewed",
      user_id: data.user_id,
      step: 1,
    });
  })
);

const webQuoteStepCompleted = async <T extends StepCompleted>(
  data: T
): Promise<void> =>
  trackEvent("Quote Form Step Completed", {
    sell_form_type: "Web Quote",
    ...data,
  });

events.on(
  ...segmentWhitelistFilter("syb_designer_selected", webQuoteStepCompleted)
);

events.on(
  ...segmentWhitelistFilter("syb_category_selected", webQuoteStepCompleted)
);

events.on(
  ...segmentWhitelistFilter("web_quote_image_upload", webQuoteStepCompleted)
);

events.on("syb_form_completed", async (data: SellFormCompletedEvent) => {
  window["optimizely"] = window["optimizely"] || [];
  window["optimizely"].push({
    type: "event",
    eventName: "syb-submit-item-success",
    tags: {
      revenue: 0, // Optional in cents as integer (500 == $5.00)
      value: 0.0, // Optional as float
    },
  });
});

events.on(
  ...segmentWhitelistFilter(
    "syb_form_completed",
    async (data: SellFormCompletedEvent) => {
      const segmentData = { ...data };
      trackEvent("Quote Form Completed", segmentData);
    }
  )
);

events.on(
  ...segmentWhitelistFilter(
    "syb_save_draft",
    async (data: SYBSaveDraftEvent) => {
      trackEvent("Quote Form Save Draft", data);
    }
  )
);

// virtual appt page
const vaStepCompleted = async <T extends StepCompleted>(
  data: T
): Promise<void> =>
  trackEvent("Appointment Form Step Completed", {
    sell_form_type: "Virtual Appointment",
    ...data,
  });
events.once(
  ...segmentWhitelistFilter(
    "virtual_appointment_date_selected",
    vaStepCompleted
  )
);
events.once(
  ...segmentWhitelistFilter(
    "virtual_appointment_time_selected",
    vaStepCompleted
  )
);
events.on(
  ...segmentWhitelistFilter(
    "virtual_appointment_submitted",
    async (data: VAFormCompletedEvent) => {
      trackEvent("Appointment Form Completed", data);
    }
  )
);

// shiplist
events.on("shiplist_submit", async (data: ShiplistSubmit) => {
  window["optimizely"] = window["optimizely"] || [];
  window["optimizely"].push({
    type: "event",
    eventName: "ship_list_submit_identifier",
    tags: data,
  });
});

export default events;
