import { CategoryType } from "../@types/ItemSurvey";
import { Category } from "../@types/brands";

export const defaultCategories: CategoryType[] = [
  {
    id: 233,
    name: "Handbags",
  },
  {
    id: 168,
    name: "Shoes",
  },
  {
    id: 164,
    name: "Accessories",
  },
  {
    id: 175,
    name: "Jewelry",
  },
  {
    id: 265,
    name: "Watches",
  },
  {
    id: 0,
    name: "Other",
  },
];

export const categoryOrder = {
  Handbags: 0,
  "SLG (Small Leather Goods)": 1,
  Accessories: 2,
  Shoes: 3,
  Sunglasses: 4,
  Belts: 5,
  Scarves: 6,
  Jewelry: 7,
  Watches: 8,
  "Vintage (20+ years)": 9,
};

export const reOrderCategories = (categories: Category[]): Category[] =>
  categories.sort((a: Category, b: Category): number => {
    const aindex = categoryOrder[a.name?.trim()];
    const bindex = categoryOrder[b.name?.trim()];
    if (aindex < 0 || bindex < 0) {
      return 1;
    }
    return aindex > bindex ? 1 : -1;
  });
