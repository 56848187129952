const fixture = {
  "87": {
    name: "Alexander Wang",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "88": {
    name: "Balenciaga",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "89": {
    name: "Bottega Veneta",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "90": {
    name: "Burberry",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "92": {
    name: "Bulgari",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "93": {
    name: "Cartier",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "94": {
    name: "Celine",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "95": {
    name: "Chanel",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 0,
        name: "Other",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "96": {
    name: "Chloe",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "97": {
    name: "Christian Dior",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 0,
        name: "Other",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "98": {
    name: "Christian Louboutin",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "99": {
    name: "David Yurman",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "100": {
    name: "Fendi",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "101": {
    name: "Givenchy",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "102": {
    name: "Goyard",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 0,
        name: "Other",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "103": {
    name: "Gucci",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 0,
        name: "Other",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "104": {
    name: "Hermes",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 0,
        name: "Other",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "105": {
    name: "Jimmy Choo",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "106": {
    name: "Judith Leiber",
    categories: [
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "107": {
    name: "Louis Vuitton",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 0,
        name: "Other",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "109": {
    name: "Miu Miu",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "111": {
    name: "Prada",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "112": {
    name: "Proenza Schouler",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "113": {
    name: "Rolex",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "114": {
    name: "Tiffany",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "115": {
    name: "Valentino",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "116": {
    name: "Van Cleef & Arpels",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "117": {
    name: "Yves Saint Laurent",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 168,
        name: "Shoes",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "118": {
    name: "Marc Jacobs",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "119": {
    name: "Chopard",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "120": {
    name: "Mikimoto",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "121": {
    name: "Harry Winston",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "237": {
    name: "Stella McCartney",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "250": {
    name: "Dolce Gabbana",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "251": {
    name: "Lanvin",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "252": {
    name: "Tom Ford",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "253": {
    name: "Alexander McQueen",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "254": {
    name: "Mulberry",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "255": {
    name: "Phillip Lim",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "256": {
    name: "Versace",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "257": {
    name: "Oliver Peoples",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "268": {
    name: "John Hardy",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "270": {
    name: "Patek Philippe",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "400": {
    name: "Panerai",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "401": {
    name: "Omega",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "402": {
    name: "Mansur Gavriel",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "403": {
    name: "Alaia",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "404": {
    name: "Loewe",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "405": {
    name: "Delvaux",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "406": {
    name: "MCM",
    categories: [
      {
        id: 164,
        name: "Accessories",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 233,
        name: "Handbags",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "411": {
    name: "IWC",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "455": {
    name: "Breitling",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "456": {
    name: "Damiani",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "462": {
    name: "Breguet",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "463": {
    name: "Vacheron Constantin",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "464": {
    name: "Audemars Piguet",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "561": {
    name: "Franck Muller",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "644": {
    name: "Hublot",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "645": {
    name: "Tudor",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "646": {
    name: "Grand Seiko",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "647": {
    name: "Jaeger Lecoultre",
    categories: [
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "648": {
    name: "Piaget",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "649": {
    name: "Boucheron",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
      {
        id: 265,
        name: "Watches",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "650": {
    name: "Messika",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "651": {
    name: "Jennifer Meyer",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "652": {
    name: "Buccellati",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "653": {
    name: "Suzanne Kalan",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "654": {
    name: "Anita Ko",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
  "655": {
    name: "Roberto Coin",
    categories: [
      {
        id: 175,
        name: "Jewelry",
        createdAt: "2021-06-24T15:48:55.000Z",
        updatedAt: "2021-06-24T15:48:55.000Z",
      },
    ],
  },
};

export default fixture;
