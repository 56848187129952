export const segmentPageNameMap = {
  "/designer-directory": {
    name: "Designer Directory",
    site_section: "Selling",
  },
  "/quote-tracker": { name: "Quote Tracker", site_section: "Selling" },
  "/virtual-appointment": {
    name: "Virtual Appointment",
    site_section: "Selling",
  },
  "/white-glove": { name: "White Glove", site_section: "Selling" },
  "/sell-your-bag": { name: "Web Quote", site_section: "Selling" },
  "/sell-your-bag?key": { name: "Item Submitted", site_section: "Selling" },
  "/sell-your-bag/consignment": {
    name: "Consignment",
    site_section: "Selling",
  },
  "/sell-with-us": { name: "Sell Homepage", site_section: "Selling" },
  "/shiplist": { name: "Ship List", site_section: "Account" },
};
