/* eslint-disable dot-notation */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getUserContext } from "../components/context/UserContext";
import { getCartContext } from "../components/context/CartContext";
import events from "./index";

/**
 * ## Usage:
 * ```
 * const { emitOnce, updateEmitOnce } = useEmitOnce("title", "photo", ...)
 *
 * if (!emitOnce.title) {
 *  ...
 *  updateEmitOnce("title")
 * }
 * ```
 */
export type UseEmitOnceState<K extends string[]> = {
  [k in K[number]]: boolean;
};
export interface UseEmitOnce<
  K extends string[] = string[],
  S extends UseEmitOnceState<K> = UseEmitOnceState<K>
> {
  emitOnce: S;
  emitReady: boolean;
  updateEmitOnce: (k: keyof S) => void;
  resetEmitOnce: () => void;
}
export const useEmitOnce = <K extends string[], S extends UseEmitOnceState<K>>(
  ...state: K
): UseEmitOnce<K, S> => {
  const [emitOnce, setEmitOnce] = useState<S>(null);
  const [emitReady, setEmitReady] = useState(false);

  const getFormatted = (): S =>
    state.reduce((acc, v) => {
      acc[v] = false;
      return acc;
    }, {}) as S;

  const resetEmitOnce = (k?: keyof S): void => {
    if (k) {
      setEmitOnce((s) => ({ ...s, [k]: false }));
    } else {
      setEmitOnce(getFormatted());
    }
  };

  useEffect(() => {
    resetEmitOnce();
    setEmitReady(true);
  }, []);

  const updateEmitOnce = (k: keyof S): void => {
    setEmitOnce((s) => ({ ...s, [k]: true }));
  };

  return { emitReady, emitOnce, updateEmitOnce, resetEmitOnce };
};

export const useEventModal = (
  type: string,
  identityId: string,
  init: boolean = false
): [boolean, (state: boolean) => void, Dispatch<SetStateAction<boolean>>] => {
  const [showModal, setModal] = useState(init);

  const handleModal = (state: boolean): void => {
    events.emit(state ? "modal_open" : "modal_close", {
      user_id: +identityId,
      type,
      url: window?.location?.href,
    });
    setModal(state);
  };

  return [showModal, handleModal, setModal];
};

export const useViewPageEvent = (pageName: string, cb?: Function): void => {
  const {
    user: { userId },
    loading: userLoading,
  } = getUserContext();
  const { loading: cartLoading } = getCartContext();

  useEffect(() => {
    if (!userLoading && !cartLoading) {
      events.emit("page_view", {
        page_name: pageName,
        userId,
      });
      cb?.();
    }
  }, [userId, userLoading, cartLoading]);
};
